import { useEffect } from "react";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Segment } from "semantic-ui-react";
import { ActivitySegment, Flashcard } from "../components/ActivityDetails";
import { SkillPhrases } from "../components/Skill";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";

const SkillScreen = () => {
    const { skillId } = useParams();
    const { currentSpace } = useAuth();
    const [skillAPI, callSkillAPI] = useAPI();
    const { setBackPath } = useTitle();
    const { setPageTitle, setNavigationTitle } = useTitle();

    setBackPath(`/spaces/${currentSpace.id}/skills`);

    useEffect(() => {
        callSkillAPI("GET", `/api/spaces/${currentSpace.id}/skills/${skillId}?include_related_skills=True`);
    }, [skillId]);

    useEffect(() => {
        if (skillAPI.response) {
            setPageTitle(skillAPI.response.name_native);
            setNavigationTitle(skillAPI.response.name_native);
        }
    }, [skillAPI.response]);

    if (skillAPI.loading || !skillAPI.response) {
        return <ZataLoader />;
    }

    return (
        <div>
            <p>{skillAPI.response.name}</p>

            <h2>{strings.vocabulary}</h2>
            <SkillPhrases skill={skillAPI.response} />

            <h2>{strings.activities}</h2>
            {skillAPI.response?.activities?.length === 0 && <p>None found</p>}
            {skillAPI.response?.activities?.map((activity) => (
                <ActivitySegment key={activity.id} activity={activity} />
            ))}

            <h2>{strings.skills_related}</h2>
            {skillAPI.response?.related_skills?.length === 0 && <p>None found</p>}
            <ul>
                {skillAPI.response?.related_skills?.map((relatedSkill) => (
                    <li>
                        <Link to={`/spaces/${currentSpace.id}/skills/${relatedSkill.id}`}>
                            {relatedSkill.name_native}
                        </Link>
                    </li>
                ))}
            </ul>

            <h2>{strings.exercises}</h2>
            {skillAPI.response?.flashcards?.map((flashcard) => (
                <Flashcard flashcard={flashcard} phrase={flashcard.phrase} />
            ))}

            {skillAPI.response?.flashcards.length === 0 && <Segment>No exercises found</Segment>}
        </div>
    );
};

export default SkillScreen;
