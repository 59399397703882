import { useEffect, useState } from "react";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Segment } from "semantic-ui-react";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { timeSince } from "../utils/time.utils";
import { EmptyState } from "../components/EmptyState";
import { useLanguage } from "../hooks/useLanguage";
import Phrase from "../components/Phrase";
import { currentLocation } from "../utils/url.utils";
import useAuth from "../hooks/useAuth";
import SegmentWithHeader from "../components/SegmentWithHeader";
import ButtonBar from "../components/ButtonBar";

const SkillsStudied = ({ skillsStudied, title }) => {
    const { nativeLanguage } = useLanguage();

    if (!skillsStudied || skillsStudied.length === 0) {
        return null;
    }

    return (
        <p>
            <i>{title}</i>
            <ul>
                {skillsStudied?.map((skill_studied) => (
                    <li>
                        {skill_studied.skill.name_native ? skill_studied.skill.name_native : skill_studied.skill.name} -{" "}
                        {skill_studied.phrases?.map((phrase, index) => (
                            <>
                                <Phrase
                                    phrase={phrase}
                                    inline={true}
                                    alignments={phrase.alignments}
                                    fromOrTarget="from"
                                    translations={phrase.translations}
                                    translationLanguage={nativeLanguage}
                                />
                                {index < skill_studied.phrases.length - 1 ? ", " : ""}
                            </>
                        ))}
                    </li>
                ))}
            </ul>
        </p>
    );
};
const ActivitySessionDetails = ({ activitySession, skillsStudied, skillsFromCorrections, skillsFromSavedWords }) => {
    const { currentSpace } = useAuth();

    return (
        <SegmentWithHeader
            icon={activitySession.activity.emoji}
            title={
                activitySession.activity.name_native
                    ? activitySession.activity.name_native
                    : activitySession.activity.name
            }>
            <p>{activitySession.summary && <p>{activitySession.summary}</p>}</p>

            <SkillsStudied skillsStudied={skillsStudied} title={"Skills reviewed"} />

            <SkillsStudied skillsStudied={skillsFromCorrections} title={"Skills to work on"} />

            <SkillsStudied skillsStudied={skillsFromSavedWords} title={"Saved words"} />

            <ButtonBar>
                {["in_progress", "expired"].includes(activitySession.status) && (
                    <Button
                        compact
                        content={"Resume"}
                        as={Link}
                        to={`/spaces/${currentSpace.id}/chat?activitySessionId=${
                            activitySession.id
                        }&back=${currentLocation()}`}
                    />
                )}
                <Button
                    compact
                    content={"View Chat"}
                    as={Link}
                    to={`/spaces/${currentSpace.id}/history/${activitySession.id}?back=${currentLocation()}`}
                />
            </ButtonBar>
            <span style={{ fontSize: "0.8125rem" }}>{timeSince(new Date(activitySession.created))}</span>
        </SegmentWithHeader>
    );
};

const AllActivityHistory = () => {
    const [historyAPI, callHistoryAPI] = useAPI();
    const { currentSpace } = useAuth();

    const { setNavigationTitle } = useTitle(strings.history_title);
    const [sessions, setSessions] = useState([]);
    const limit = 10;

    function loadMore() {
        console.log("Loading more at", sessions.length);
        callHistoryAPI("GET", `/api/spaces/${currentSpace.id}/feed?limit=${limit}&offset=${sessions.length}`);
    }

    function refresh() {
        console.log("Refreshing");
        setSessions([]);
        callHistoryAPI("GET", `/api/spaces/${currentSpace.id}/feed?limit=${limit}&offset=0`);
    }

    useEffect(() => {
        if (historyAPI.response) {
            setSessions((sessions) => [...sessions, ...historyAPI.response]);
        }
    }, [historyAPI.response]);

    useEffect(() => {
        loadMore();
    }, []);

    setNavigationTitle(strings.history);

    return (
        <div>
            <InfiniteScroll
                dataLength={sessions.length}
                next={loadMore}
                hasMore={historyAPI.loading || (historyAPI.response && historyAPI.response.length === limit)}
                loader={<ZataLoader />}
                endMessage={<EmptyState message={strings.history_do_activities} />}
                refreshFunction={refresh}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>}
                releaseToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>}
                scrollableTarget="content">
                {sessions.map((activity_session) => (
                    <ActivitySessionDetails
                        activitySession={activity_session.activity_session}
                        skillsStudied={activity_session.skills_studied}
                        skillsFromCorrections={activity_session.skills_from_corrections}
                        skillsFromSavedWords={activity_session.skills_from_saved_words}
                    />
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default AllActivityHistory;
