import { Button } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ActivityHelpModal } from "./ActivityHelpModal";
import { PromptHints } from "./PromptHints";
import useAuth from "../hooks/useAuth";
import {
    Archive,
    ArrowArcRight,
    Check,
    EarSlash,
    Eye,
    House,
    Keyboard,
    Lifebuoy,
    Lightbulb,
    List,
    Microphone,
    Prohibit,
    Repeat,
    SkipForward,
    ThumbsUp,
    UserSound,
} from "@phosphor-icons/react";
import { MapTrifold } from "@phosphor-icons/react";

const PromptButtonBar = ({
    prompt,
    lastMessage,
    replyCommands,
    hasGrammarCorrections,
    disabled,
    sendCommand,
    onResize,
    textMode,
    setTextMode,
}) => {
    const { language, nativeLanguage } = useParams();
    const history = useHistory();
    const [showTasks, setShowTasks] = useState(false);
    const [showHints, setShowHints] = useState(false);
    const { user } = useAuth();
    const flashcard = prompt.messages?.find((message) => message.flashcard_review?.flashcard)?.flashcard_review
        .flashcard;

    useEffect(() => {
        if (showTasks || showHints) {
            onResize();
        }
    }, [showTasks, showHints]);

    function showOrHideHints() {
        setShowHints(!showHints);
        if (!showHints) {
            setShowTasks(false);
        }
    }

    function showOrHideTasks() {
        setShowTasks(!showTasks);
        if (!showTasks) {
            setShowHints(false);
        }
    }

    if (
        (!replyCommands || replyCommands?.length === 0) &&
        !["discuss", "role_play", "journal", "talk"].includes(prompt.activity.type)
    ) {
        return;
    }

    return (
        <div>
            {showTasks && (
                <div className={"prompt-tasks"}>
                    {showTasks && (
                        <>
                            <div style={{ marginBottom: "0.25rem" }}>{strings.tasks_preface}</div>
                            {prompt.activity?.activity_tasks
                                .filter((task) => task.type === "task")
                                .map((task, index) => (
                                    <div>🎯 {task.description_native}</div>
                                ))}
                        </>
                    )}
                </div>
            )}

            {showHints && !hasGrammarCorrections && ["discuss", "role_play", "talk"].includes(prompt.prompt_type) && (
                <PromptHints prompt={prompt} lastMessage={lastMessage} onResize={onResize} />
            )}

            <div className="command-options">
                {lastMessage?.action_type !== "buttons" && (
                    <>
                        {(["tutor", "talk", "comprehension", "discuss"].includes(prompt.prompt_type) ||
                            (flashcard && flashcard.type == "repeat_after_me")) && (
                            <Button.Group>
                                <Button
                                    content={strings.stt_speak}
                                    icon={<Microphone weight="bold" />}
                                    compact={true}
                                    size={"small"}
                                    onClick={() => setTextMode(false)}
                                    active={!textMode}
                                    disabled={disabled}
                                />
                                <Button
                                    content={strings.stt_type}
                                    icon={<Keyboard weight="bold" />}
                                    compact={true}
                                    size={"small"}
                                    onClick={() => setTextMode(true)}
                                    active={textMode}
                                    disabled={disabled}
                                />
                            </Button.Group>
                        )}

                        {["discuss", "role_play", "talk"].includes(prompt.prompt_type) && (
                            <Button
                                content={strings.prompt_hints}
                                icon={<Lightbulb weight="bold" />}
                                compact={true}
                                size={"small"}
                                disabled={disabled || hasGrammarCorrections}
                                active={showHints && !hasGrammarCorrections}
                                onClick={showOrHideHints}
                            />
                        )}

                        {["discuss", "role_play"].includes(prompt.prompt_type) && (
                            <Button
                                content={strings.tasks}
                                icon={<List weight="bold" />}
                                compact={true}
                                size={"small"}
                                onClick={showOrHideTasks}
                                disabled={disabled}
                                active={showTasks}
                            />
                        )}

                        {false && ["grammar", "vocab", "tutor"].includes(prompt.prompt_type) && user.features && (
                            <ActivityHelpModal
                                activityId={prompt.activity.activity_collection_id}
                                trigger={
                                    <Button
                                        content={"Guide"}
                                        icon={<MapTrifold weight="bold" />}
                                        compact={true}
                                        size={"small"}
                                        disabled={disabled}
                                    />
                                }
                            />
                        )}
                    </>
                )}

                {replyCommands.map((option, index) => (
                    <Button
                        key={"command-" + option.command}
                        compact={true}
                        size={"small"}
                        onClick={() => sendCommand(option.command, option.label)}
                        disabled={disabled || option.disabled}
                        icon={
                            {
                                eye: <Eye weight="bold" />,
                                archive: <Archive weight="bold" />,
                                "step forward": <SkipForward weight="bold" />,
                                repeat: <Repeat weight="bold" />,
                                lifebouy: <Lifebuoy weight="bold" />,
                                "ear-slash": <EarSlash weight="bold" />,
                                check: <Check weight="bold" />,
                                thumbsup: <ThumbsUp weight="bold" />,
                                prohibit: <Prohibit weight="bold" />,
                                "user-sound": <UserSound weight="bold" />,
                                "arrow-arc-right": <ArrowArcRight weight="bold" />,
                                lightbulb: <Lightbulb weight="bold" />,
                            }[option.icon]
                        }
                        content={
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: option.label,
                                }}></span>
                        }></Button>
                ))}
            </div>

            {/* The task failed. Give the user a way out. */}
            {prompt && lastMessage?.tag === "task" && lastMessage.task?.status === "FAILED" && (
                <div style={{ marginTop: "1rem" }}>
                    <Button
                        compact={true}
                        primary
                        size={"small"}
                        icon={<House />}
                        content={strings.home}
                        onClick={() => {
                            history.push(`/languages/${language}/${nativeLanguage}/chat?promptType=default`);
                        }}
                        disabled={disabled}></Button>
                </div>
            )}
        </div>
    );
};

export default PromptButtonBar;
