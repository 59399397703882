import { ArrowArcRight, Barbell, Book, Brain, Check, Lightbulb, Trash } from "@phosphor-icons/react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import LoadableButton from "./LoadableButton";
import Phrase from "./Phrase";
import SegmentWithHeader from "./SegmentWithHeader";

export const SkillPhrases = ({ skill }) => {
    const { currentSpace } = useAuth();

    return skill.vocab?.map((phrase, index) => (
        <>
            {(phrase.known_already || phrase.inferred || phrase.flashcard_count > 0) && (
                <>
                    {phrase.known_already && <ArrowArcRight />}
                    {phrase.inferred && <Lightbulb />}
                    {!phrase.known_already &&
                        !phrase.inferred &&
                        phrase.forgetting_index !== null &&
                        phrase.forgetting_index <= 0.001 && <Check />}{" "}
                    {!phrase.known_already &&
                        !phrase.inferred &&
                        phrase.forgetting_index !== null &&
                        phrase.forgetting_index > 0.001 && <Barbell />}{" "}
                </>
            )}
            <Phrase phrase={phrase} translationLanguage={currentSpace.native_language} inline={true} />
            {index < skill.vocab.length - 1 ? ", " : ""}
        </>
    ));
};

const Skill = ({ skill, removeSkill, showOutcomes = false }) => {
    const { currentSpace } = useAuth();
    const [removeSkillFromSpaceAPI, callRemoveSkillFromSpaceAPI] = useAPI();

    function removeSkillFromSpace() {
        callRemoveSkillFromSpaceAPI("DELETE", `/api/spaces/${currentSpace.id}/skills/${skill.id}`);
    }

    useEffect(() => {
        if (removeSkillFromSpaceAPI.response) {
            removeSkill();
        }
    }, [removeSkillFromSpaceAPI.response]);

    return (
        <SegmentWithHeader
            key={skill.id}
            actions={
                removeSkill && (
                    <LoadableButton
                        compact
                        onClick={removeSkillFromSpace}
                        loading={removeSkillFromSpaceAPI.loading}
                        icon={<Trash />}
                    />
                )
            }
            title={
                <>
                    <Link to={`/spaces/${currentSpace.id}/skills/${skill.id}`}>
                        {skill.topic ? skill.topic.name : ""}
                        {skill.name_native}
                    </Link>
                    <p style={{ fontSize: "0.8rem" }}>{skill.name}</p>
                </>
            }>
            <SkillPhrases skill={skill} />
        </SegmentWithHeader>
    );
};

export default Skill;
