import { Segment } from "semantic-ui-react";
import "./SegmentWithHeader.css";
import { Row } from "./Flex";

const SegmentWithHeader = ({ icon, title, actions, style, children, id, disabled }) => {
    return (
        <Segment id={id} className="with-header" style={style}>
            <Row className="header">
                {icon && (
                    <div className="icon" style={{ flexGrow: 0 }}>
                        {icon}
                    </div>
                )}
                {title && (
                    <div className="title" style={{ flexGrow: 1 }}>
                        {title}
                    </div>
                )}
                {actions && (
                    <div className="actions" style={{ flexGrow: 0 }}>
                        {actions}
                    </div>
                )}
            </Row>
            {children && <div className="content">{children}</div>}
        </Segment>
    );
};

export default SegmentWithHeader;
