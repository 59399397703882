import { Alarm, Shuffle, ThumbsDown } from "@phosphor-icons/react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Button, Loader, Segment } from "semantic-ui-react";
import ButtonBar from "../components/ButtonBar";
import useAuth from "../hooks/useAuth";
import { strings } from "../utils/i18n.utils";
import SegmentWithHeader from "./SegmentWithHeader";
import EmojiIcon from "./EmojiIcon";
import { ActivitySegment } from "./ActivityDetails";

const NextUp = ({ plan }) => {
    return (
        <>
            {plan.activities?.map((activity) => (
                <ActivitySegment key={activity.id} activity={activity} />
            ))}

            {plan.plan_generation_task_id && (
                <Segment>
                    <Loader active={true} inline={true} size="tiny" /> {strings.building_study_plan}
                </Segment>
            )}

            {plan.plan_generation_task_id === null && plan.activities?.length === 0 && (
                <Segment>{strings.nothing_to_study}</Segment>
            )}
        </>
    );
};

export default NextUp;
